export function getImageCoverSize({
  image,
  view,
  viewForPositionCalculation,
  zoomToFit
}: IGetImageCoverSizeParams) {
  const imgAspectRatio = image.width / image.height;
  const elementAspectRatio = view.width / view.height;

  let width: number = 0;
  let height: number = 0;
  if (elementAspectRatio <= imgAspectRatio) {
    height = view.height;
    width = height * imgAspectRatio;
  } else {
    width = view.width;
    height = width / imgAspectRatio;
  }

  if (zoomToFit) {
    const distanceFromFocalPointToViewBottom = view.height - viewForPositionCalculation.height / 2;
    const distanceFromFocalPointToResizedIMageBottom =
      height - height * (image.focalPoint.yPercent / 100);
    const distanceFromFocalPointToImageBottom =
      image.height - image.height * (image.focalPoint.yPercent / 100);
    const zoomCoefficient =
      distanceFromFocalPointToImageBottom !== 0
        ? distanceFromFocalPointToViewBottom / distanceFromFocalPointToImageBottom
        : 1;

    if (
      zoomCoefficient !== 1 &&
      distanceFromFocalPointToViewBottom > distanceFromFocalPointToResizedIMageBottom
    ) {
      height = image.height * zoomCoefficient;
      width = height * imgAspectRatio;
    }
  }

  return {
    width,
    height
  };
}

export interface IGetImageCoverSizeParams {
  image: {
    width: number;
    height: number;
    focalPoint: {
      xPercent: number;
      yPercent: number;
    };
  };
  view: {
    width: number;
    height: number;
  };
  viewForPositionCalculation: {
    width: number;
    height: number;
  };
  zoomToFit: boolean;
}

export interface IImageSize {
  width: number;
  height: number;
}
