import { Component } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { get } from 'lodash';
import { hotjar } from 'react-hotjar';
import { BrowserRouter as Router } from 'react-router-dom';

import '@openfonts/bellota_all';
import 'font-awesome/css/font-awesome.min.css';
import 'react-toastify/dist/ReactToastify.css';
import 'simple-line-icons/css/simple-line-icons.css';
import 'typeface-josefin-sans';
import 'typeface-lato';
import 'typeface-montserrat';
import 'typeface-playfair-display';
import 'typeface-roboto';
import 'typeface-roboto-slab';
import 'scss/style.css';

import { AccountLoginModal } from 'components/AccountLoginModal';
import { CancelSelectionModal } from 'components/CancelSelectionModal';
import { CreateAccountWithPasswordModal } from 'components/CreateAccountWithPasswordModal';
import { DownloadModal } from 'components/DownloadModal';
import { DownloadPinModal } from 'components/DownloadPinModal';
import { AppModules } from 'modules';
import { InfoModal } from 'components/InfoModal';
import { PasswordPage } from 'components/PasswordPage';
import { PhotographerModal } from 'components/PhotographerModal';
import { SaveSelectionModal } from 'components/SaveSelectionModal';
import { SelectionSentCongratsModal } from 'components/SelectionSentCongratsModal';
import { SendSelectionModal } from 'components/SendSelectionModal';
import { ShareModal } from 'components/ShareModal';
import { Tracking } from 'components/Tracking';
import { UpsellModal } from 'components/UpsellModal';
import camelCaseToKebabCase from 'helpers/camel-case-to-kebab-case';
import { getImageUrls } from 'helpers/imageUrls';
import { preloadImage } from 'helpers/preloader';
import { getSortedSliderImages } from 'helpers/sliderImages';
import { CreateAccountEmailModal } from 'modules/ScrShop/components/CreateAccountWithPasswordModal';
import ForgotPasswordModal from 'modules/ScrShop/components/ForgotPasswordModal';
import LoginWithPasswordAccountModal from 'modules/ScrShop/components/LoginWithPasswordAccountModal';
import { createFontLink } from 'modules/ScrShop/store/utils/helpers/createFontLink';
import AppInstallationAlertHint from './components/AppInstallationAlertHint';
import { CommentModal } from './components/CommentModal';
import { SingleImageDownloadModal } from 'components/SingleImageDownloadModal';
import { DownloadTourModal } from 'components/Tours/DowloadTourModal';
import { addMetaIconTags, getAppIcon } from './helper';
import { clearAllCookies, getCookiesByteSize, getCookie } from 'helpers/cookie';
import * as actions from 'old-store/actions';
import * as shopActions from 'modules/ScrShop/store/actions/shop';
import * as photobookActions from 'modules/ScrPhotobook/store/slices/photobook/actions';
import { ACTIONS, SCOPES } from './old-store/constants/statistics';
import history from 'modules/ScrAppBook/history';
import { DesignThemeProvider } from 'components/DesignThemeProvider';
import { SelectionActionsModal } from 'components/SelectionActionsModal';
import { Message } from 'components/Messages';
import { trackCollectionView } from 'store/slices/messages/actions';
import { DefaultLoader } from 'components/Loaders/DefaultLoader';
import { DiscountModal } from 'modules/ScrShopNew/components/DiscountModal';
import AppInstallationScrAppBookPage from 'components/AppInstallationScrAppBookPage';
import { fetchCustomerToken } from 'store/slices/selection/actions';
import { isTouchDevice } from 'helpers/deviceDetection';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { MobileDragLayer } from 'modules/ScrPhotobook/pages/PhotobookEdit/EditorContent/MobileDragLayer';
import DesktopDragLayer from 'modules/ScrPhotobook/pages/PhotobookEdit/EditorContent/DesktopDragLayer';

toast.configure();

const allowedPaths = [
  '/photobook/preview',
  '/shop/basket',
  '/shop/shipping',
  '/shop/payment',
  '/shop/confirm'
];

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pathname: window.location.pathname
    };
  }

  UNSAFE_componentWillMount() {
    if (process.env.NODE_ENV === 'development') {
      console.log('API_URL:', window.API_URL);
      console.log('CDN_URL:', window.CDN_URL);
      console.log('environment:', this.props.environment);
    }

    if (this.props.environment.isPortfolioModule) return;

    this.loadSiteData();
  }

  componentDidMount() {
    try {
      if (this.props.environment.isPortfolioModule) return;

      this.props.fetchSlideshows();
      this.props.fetchTemplateFonts();
      this.props.getCollectionCreator();

      if (window.HJ_ID && !hotjar.initialized()) {
        hotjar.initialize(window.HJ_ID, 6);
      }

      history.listen(({ location }) => {
        if (this.state.pathname !== location.pathname) {
          this.setState({ pathname: location.pathname });
        }
      });

      window.USER_IS_TOUCHING = true;
    } catch (e) {
      console.log(e);
    }
  }

  UNSAFE_componentWillReceiveProps() {
    if (this.props.environment.isPortfolioModule) return;

    const fontsToLoad = this.props.templates.fonts.filter(
      (font) => !this.props.templates.loadedFonts.includes(font.name)
    );

    if (fontsToLoad.length > 0) {
      createFontLink(this.props.templates.fonts);
      this.props.setLoadedFonts(fontsToLoad.map((f) => f.name));
    }

    if (this.props.templates.photobookFonts.length > 0) {
      createFontLink(this.props.templates.photobookFonts);
    }
  }

  componentDidUpdate() {
    if (this.props.environment.isPortfolioModule) return;

    const { collection, images, setShareLink } = this.props;

    if (collection._id) {
      const appIcon = getAppIcon(images.images);
      addMetaIconTags(appIcon);

      if (collection.custom_domain && !this.shareLinkSet) {
        this.shareLinkSet = true;
        setShareLink({
          type: 'collection',
          url: custom_domain.custom_domain
        });
      }

      const { design } = collection;

      if (!design) return;

      if (design.variables) {
        const [templateWrapper] = document.getElementsByClassName('ScrAppBook');

        if (!templateWrapper) return;

        // eslint-disable-next-line
        for (const [key, value] of Object.entries(design.variables)) {
          // eslint-disable-next-line
          if (!value) continue;
          const fontVariables = ['font', 'headingFont'];

          templateWrapper.style.setProperty(
            `--${camelCaseToKebabCase(key)}`,
            fontVariables.includes(key) ? `'${value}'` : value
          );
        }
      }

      // const MAX_COOKIE_SIZE = 4096;
      const MAX_COOKIE_SIZE = 3096; // max size is 4096, but there are http only cookies, that are not available in order to reserve some place

      if (getCookiesByteSize() >= MAX_COOKIE_SIZE) {
        clearAllCookies();
      }

      const fontsToLoad = design.loadFonts.filter(
        (font) => !this.props.templates.loadedFonts.includes(font.name)
      );
      if (fontsToLoad.length > 0) {
        createFontLink(fontsToLoad);
        this.props.setLoadedFonts(fontsToLoad.map((f) => f.name));
      }
    }
  }

  getBaseUrl() {
    const { collection } = this.props;

    if (collection.isShareSite) return `/share/${window.SITE_ID}`;
    if (collection._id) return `/${window.SITE_PATH}`;

    return '/';
  }

  async loadSiteData() {
    const {
      environment: { isBuilder, siteTracking, isApp },
      getCollectionById,
      getCollectionFields,
      logStatistics,
      getDownloadToken,
      getEndCustomer,
      fetchPhotobooks,
      fetchLayouts,
      setCollectionFetchedStatus
    } = this.props;

    let collection = null;

    try {
      console.log('[APP-START] start loadSiteData');

      this.loadAppInstallationState();

      const initialFieldsArr = ['design', 'title', 'templateName', 'type', 'language', 'user'];
      const initialData = await getCollectionFields(initialFieldsArr);

      await fetchCustomerToken(initialData.user);

      const getCollectionResult = await getCollectionById(window.SITE_ID);

      if (getCollectionResult) {
        collection = getCollectionResult.collection;

        const { images } = getCollectionResult;

        let titleImages = getSortedSliderImages(images, collection.galleries);

        titleImages = titleImages.filter(
          (i) =>
            i.extension.toLowerCase() !== 'gif' ||
            (i.extension.toLowerCase() === 'gif' && i.sizeMb < 5)
        );

        if (titleImages.length > 2) {
          titleImages = titleImages.slice(0, 2);
        }

        titleImages = titleImages.map((image) => {
          const imageUrls = getImageUrls(image, collection.signedWildCardUrl);

          return {
            ...image,
            ...imageUrls
          };
        });

        const imagesPromiseList = [];

        titleImages.forEach((image) => imagesPromiseList.push(preloadImage(image.url)));

        await Promise.all(imagesPromiseList);
      }
    } catch (e) {
      console.log('[APP-START] loadSiteDataError:', e);
    } finally {
      if (collection) {
        console.log('[APP-START] successRendering rendering will start');
        getDownloadToken();
        getEndCustomer();
        fetchPhotobooks({});
        fetchLayouts();

        setCollectionFetchedStatus(true);

        if (!isBuilder && siteTracking) {
          trackCollectionView();
          logStatistics({
            action: ACTIONS.VIEW,
            scope: collection.isShareSite ? SCOPES.SHARE : isApp ? SCOPES.APP : SCOPES.COLLECTION // eslint-disable-line
          });
        }
      } else {
        console.log('[APP-START] no rendering ');
      }
    }
  }

  loadAppInstallationState() {
    const { setAppInstallationState, environment, logStatistics } = this.props;
    try {
      console.log('[APP-START] start loadAppInstallationState');
      if (environment.isApp) {
        if (getCookie(`appInstallationState-${window.SITE_ID}`) !== 'installed') {
          logStatistics({
            action: ACTIONS.APP_INSTALL,
            scope: SCOPES.APP
          });
        }
        setAppInstallationState('installed');
      } else {
        setAppInstallationState(getCookie(`appInstallationState-${window.SITE_ID}`) || 'unset');
      }
    } catch (e) {
      console.log(e);
      console.log('[APP-START] catch error in app.js loadAppInstallationState =>', e);
      window.logToServer(e);
    }
  }

  renderRoutes() {
    return (
      <Router basename={this.getBaseUrl()}>
        <DownloadModal />
        <InfoModal />
        <PhotographerModal />
        <ShareModal />
        <Message />
        <AppInstallationAlertHint />
        <SingleImageDownloadModal />
        <DownloadPinModal />
        <DownloadTourModal />
        <LoginWithPasswordAccountModal />
        <ForgotPasswordModal />
        <CommentModal />
        <CreateAccountEmailModal />
        <SendSelectionModal />
        <SaveSelectionModal />
        <CancelSelectionModal />
        <UpsellModal />
        <SelectionActionsModal />
        <CreateAccountWithPasswordModal />
        <AccountLoginModal />
        <SelectionSentCongratsModal />
        <DiscountModal />
        <AppModules />
      </Router>
    );
  }

  renderContent() {
    const { collection, environment, user } = this.props;
    const isShopSection = get(history, 'location.pathname', '').includes('shop');
    const isPhotobookSection = get(history, 'location.pathname', '').includes('photobook');
    const isTouchableDevice = isTouchDevice();

    if (collection.checkPw) {
      const isPathAllowed = allowedPaths.some((path) => window.location.pathname.includes(path));

      if (!isPathAllowed) return <PasswordPage />;
    }

    // page is not loaded yet
    if (!collection.fetched && !environment.isBuilder && !environment.isPortfolioModule) {
      return <DefaultLoader />;
    }

    if (
      !environment.isPreview &&
      !environment.isPortfolioModule &&
      !isShopSection &&
      !isPhotobookSection &&
      !window.CRYPT_IMG_NAME &&
      environment.displayWebAppInstallation &&
      collection.showAppInstall &&
      (user.appInstallation === 'unset' ||
        user.appInstallation === 'accepted' ||
        user.appInstallation === 'forced')
    ) {
      return <AppInstallationScrAppBookPage />;
    }

    return (
      <div>
        <DndProvider
          backend={isTouchableDevice ? TouchBackend : HTML5Backend}
          options={{ delayTouchStart: 200 }}
        >
          {this.renderRoutes()}
          <Tracking />
          {isTouchableDevice ? <MobileDragLayer /> : <DesktopDragLayer />}
        </DndProvider>
      </div>
    );
  }

  render() {
    return <DesignThemeProvider>{this.renderContent()}</DesignThemeProvider>;
  }
}

function mapStateToProps(state) {
  return state;
}

export default connect(mapStateToProps, { ...actions, ...shopActions, ...photobookActions })(App);
