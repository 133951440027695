import { Component } from 'react';
import { Row, Col, Alert } from 'reactstrap';
import { connect } from 'react-redux';
import classnames from 'classnames';

import { getTranslationKey } from 'helpers/texting';
import * as actions from '../old-store/actions';
import styled from 'styled-components';
import { ReactComponent as CheckCircle } from 'assets/images/check-circle-app-download-hint.svg';
import { getImageCoordsForFocalPoint } from '../helpers/get-image-coords-for-focal-point';
import { getImageCoverSize } from '../helpers/get-image-cover-size';

const AppInstallerHintContainer = styled.div`
  font-family: 'Noto Sans', sans-serif !important;
  font-weight: 400;
`;

const AppInstallerHintTitle = styled.p`
  font-size: 28px;
  text-align: left;
  color: #1d1d1d !important;
  margin-bottom: 24px;
`;

const AppInstallerHintList = styled.ul`
  margin-bottom: 35px;
  padding-left: 0;
`;

const AppInstallerHintListItem = styled.li`
  list-style-type: none;
  font-size: 18px;
  text-align: left;
  color: #1d1d1d !important;
  font-weight: 300;
  word-spacing: 2px;
  display: flex;
  justify-content: start;
  align-items: start;
  gap: 9px;

  span {
    color: #1d1d1d !important;
  }
`;

const AppInstallerHintBtn = styled.button`
  text-align: center;
  padding: 10px 20px 10px 20px;
  font-size: 14px;
  background-color: #212020;
  color: white;
  border: 4px solid #212020;
  width: 100%;
  letter-spacing: 1.26px;
  font-family: 'Noto Sans', sans-serif !important;
`;

const AppInstallerHintLink = styled.p`
  text-align: center;
  color: #9b9a9a !important;
  cursor: pointer;
  text-decoration: underline;
  margin-top: 21px;
  text-underline-offset: 6px;
  opacity: 0.75;
  font-family: 'Noto Sans', sans-serif !important;
`;

const AppInstallerListIconContainer = styled.span`
  display: flex;
  justify-content: start;
  align-items: center;
  height: 27px;
  font-family: 'Noto Sans', sans-serif !important;
`;

const AppInstallerListIcon = styled(CheckCircle)`
  text-align: center;
  vertical-align: middle;
  font-size: 20px;
  color: #212020;
`;

const AppInstallerGalleryIconContainer = styled.div`
  min-width: 350px;
  background-color: #f9f9f9;
  border-radius: 22px;
  padding: 36px 40px 24px;
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: column;
  gap: 16px;
`;

const AppInstallerGalleryIconText = styled.p`
  color: #1d1d1d;
  font-size: 14px;
  font-family: 'Noto Sans', sans-serif !important;
`;
const AppInstallerGalleryIconsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: start;
  gap: 17px;
`;

const AppInstallerGalleryIcon = styled.div`
  height: 5em;
  width: 5em;
  border-radius: 1em;
  box-shadow: 0 0.2em 0.4em #0000003e, inset 0 0.07em 0 #0000003e;
  padding: 0;
  border: 0;
`;
const AppInstallerGalleryMainIconContainer = styled.div`
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  border-radius: 1em;
`;

const AppInstallerGalleryIconSkeleton = styled.div`
  padding: 0;
  border: 0;
  height: 5em;
  width: 5em;
  border-radius: 1em;
  background-color: #ededed;
`;

class AppInstallationScrAppBookPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedDevice: null
    };

    this.declineAppInstallation = this.declineAppInstallation.bind(this);
    this.acceptAppInstallation = this.acceptAppInstallation.bind(this);
    this.selectDevice = this.selectDevice.bind(this);
    this.icon = null;
  }

  UNSAFE_componentWillMount() {
    if (!document.body.className.includes('white-background')) {
      document.body.className += ' white-background';
    }
  }

  componentWillUnmount() {
    if (document.body.className.includes('white-background')) {
      document.body.className = document.body.className.replace('white-background', '');
    }
  }

  selectDevice(selectedDevice) {
    this.setState({ selectedDevice });
  }

  declineAppInstallation() {
    this.props.showAppInstallationAlertHint();
    this.props.setAppInstallationState('declined');
  }

  acceptAppInstallation() {
    if (window.deferredPrompt) {
      window.deferredPrompt.prompt();
      // Wait for the user to respond to the prompt
      window.deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          window.logToServer('User accepted the A2HS prompt');
        } else {
          console.logToServer('User dismissed the A2HS prompt');
          this.declineAppInstallation();
        }
        window.deferredPrompt.deferredPrompt = null;
      });
    } else {
      this.props.setAppInstallationState('accepted');
    }
  }

  showAppInstallationManual() {
    const { environment } = this.props;
    const { selectedDevice } = this.state;

    if (selectedDevice === 'ios' || environment.isIDevice) {
      return this.renderIosAppInstallationManual(environment);
    }
    if (selectedDevice === 'android' || environment.isAndroidDevice) {
      return this.renderAndroidAppInstallationManual(environment);
    }

    return this.renderNotSupportedDevice();
  }

  renderNotSupportedDevice() {
    return (
      <div>
        <Alert color="danger">{getTranslationKey('appinstallation.notSupportedDevice')}</Alert>
        <p className="m-t-10">
          {getTranslationKey('appinstallation.notSupportedDeviceDescription1')}
          <br />
          <br />
          {getTranslationKey('appinstallation.notSupportedDeviceDescription2')}
        </p>
        <button onClick={() => this.selectDevice('ios')} className="site-button w-100-p">
          {getTranslationKey('appinstallation.ios.device')}
        </button>
        <button onClick={() => this.selectDevice('android')} className="site-button w-100-p m-t-10">
          {getTranslationKey('appinstallation.android.device')}
        </button>
      </div>
    );
  }

  renderAndroidAppInstallationManual(environment) {
    if (environment.isNotChromeOnAndroid) {
      return (
        <div>
          <Alert color="danger">{getTranslationKey('appinstallation.wrongBrowser')}</Alert>
          <p className="m-t-10">{getTranslationKey('appinstallation.android.openInChrome')}</p>
          <p className="m-t-10">{window.location.href.replace(window.location.search, '')}</p>
          <a
            className="site-button"
            href={`intent://${window.location.href.replace(
              'https://',
              ''
            )}#Intent;scheme=https;package=com.android.chrome;end`}
          >
            {getTranslationKey('appinstallation.android.openChrome')}
          </a>
        </div>
      );
    }

    return (
      <div>
        <h2 className="mb-5 app-installation-title">
          {getTranslationKey('appinstallation.headlineManual')}
        </h2>
        {this.renderIcon()}

        <ol className="app-installation-steps mt-5">
          <li>
            {getTranslationKey('appinstallation.android.manual1')}:{' '}
            <span className="android-menu-button m-t-15 m-b-15" />
            <br />
            {getTranslationKey('appinstallation.android.manual2')}{' '}
            <strong>{getTranslationKey('appinstallation.android.manual3')}</strong>{' '}
            {getTranslationKey('appinstallation.android.tap')}
          </li>
          <li>{getTranslationKey('appinstallation.android.manual4')}</li>
        </ol>
      </div>
    );
  }

  renderIosAppInstallationManual(environment) {
    if (environment.isNotSafariOnIos) {
      return (
        <div>
          <Alert color="danger">{getTranslationKey('appinstallation.wrongBrowser')}</Alert>
          <p className="m-t-10">{getTranslationKey('appinstallation.ios.openInSafari')}</p>
          <p className="m-t-10">{window.location.href.replace(window.location.search, '')}</p>
          <a className="site-button" href="x-web-search://" target="_blank">
            {getTranslationKey('appinstallation.ios.openSafari')}
          </a>
        </div>
      );
    }

    return (
      <div>
        <h2 className="mb-5 app-installation-title">
          {getTranslationKey('appinstallation.headlineManual')}
        </h2>

        {this.renderIcon()}

        <ol className="app-installation-steps mt-5">
          <li>
            {getTranslationKey('appinstallation.ios.manual1')}:{' '}
            <span className="ios-safari-share-button m-t-5 m-b-5" />
          </li>
          <li>
            {getTranslationKey('appinstallation.ios.select')}{' '}
            <strong>{getTranslationKey('appinstallation.ios.manual3')}</strong>
          </li>
        </ol>

        <hr />
      </div>
    );
  }

  renderIcon() {
    const { collection, images } = this.props;

    if (images && images.images && images.images.length) {
      if (!this.icon) {
        let appIcon = images.images.find((image) => image.isIcon);
        if (!appIcon) appIcon = images.images[0]; // eslint-disable-line

        this.icon = appIcon;
      }

      const { width, height } = getImageCoverSize({
        image: {
          width: this.icon?.width || 80,
          height: this.icon?.height || 80,
          focalPoint: { xPercent: 50, yPercent: 50 }
        },
        view: { width: 80, height: 80 },
        viewForPositionCalculation: { width: 80, height: 80 },
        zoomToFit: false
      });

      const { x, y } = getImageCoordsForFocalPoint({
        image: { width, height, focalPoint: { xPercent: 50, yPercent: 50 } },
        view: { width: 80, height: 80 }
      });

      const styles = {
        backgroundImage: `url(${this.icon.url})`,
        backgroundPosition: `${x}px ${y}px`,
        backgroundSize: `${width}px ${height}px`
      };

      return (
        <AppInstallerGalleryIconContainer>
          <AppInstallerGalleryIconsContainer>
            <AppInstallerGalleryIconSkeleton />
            <AppInstallerGalleryIcon>
              <AppInstallerGalleryMainIconContainer style={styles} />
            </AppInstallerGalleryIcon>
            <AppInstallerGalleryIconSkeleton />
          </AppInstallerGalleryIconsContainer>
          <AppInstallerGalleryIconText>
            {getTranslationKey('appinstallation.galleryTitle', { galleryName: collection.title })}
          </AppInstallerGalleryIconText>
        </AppInstallerGalleryIconContainer>
      );
    }

    return null;
  }

  renderAppInstallationHint() {
    return (
      <AppInstallerHintContainer>
        <AppInstallerHintTitle>
          {getTranslationKey('appinstallation.descriptionScrAppBook')}
        </AppInstallerHintTitle>

        <AppInstallerHintList>
          {Object.keys(getTranslationKey('appinstallation.benefits')).map((benefit) => (
            <AppInstallerHintListItem key={benefit}>
              <AppInstallerListIconContainer>
                <AppInstallerListIcon />
              </AppInstallerListIconContainer>
              <span>{getTranslationKey('appinstallation.benefits')[benefit]}</span>
            </AppInstallerHintListItem>
          ))}
        </AppInstallerHintList>

        {this.renderIcon()}
        <div className="mt-4">
          <AppInstallerHintBtn onClick={this.acceptAppInstallation}>
            {getTranslationKey('appinstallation.showManual').toUpperCase()}
          </AppInstallerHintBtn>
          <AppInstallerHintLink
            onClick={this.declineAppInstallation}
            onKeyDown={this.declineAppInstallation}
          >
            {getTranslationKey('appinstallation.continueInBrowser')}
          </AppInstallerHintLink>
        </div>
      </AppInstallerHintContainer>
    );
  }

  render() {
    const {
      user: { appInstallation },
      collection: { templateName }
    } = this.props;

    if (window.location.pathname.includes('/photobook')) return;

    return (
      <div className="app-installation-container white-background h-100 w-100">
        <Row className="justify-content-center text-center">
          <Col className="m-t-20" xl="4" lg="6" md="6" sm="12" xs="12">
            {appInstallation === 'unset'
              ? this.renderAppInstallationHint()
              : this.showAppInstallationManual()}

            {appInstallation !== 'unset' && (
              <p
                onClick={this.declineAppInstallation}
                onKeyDown={this.declineAppInstallation}
                className={classnames('m-t-15 cursor-pointer sticky-bottom-center p-b-20', {
                  'white-background': templateName && !templateName.includes('SpringVibes')
                })}
              >
                <span className="text-underline">{getTranslationKey('appinstallation.abort')}</span>
              </p>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

function mapStateToProps({ collection, images, environment, user }) {
  return { collection, images, user, environment };
}

export default connect(mapStateToProps, actions)(AppInstallationScrAppBookPage);
