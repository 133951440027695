import { createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit';
import { saveRequest } from 'modules/ScrPhotobook/store/slices/photobook';
import {
  addImageToThePage,
  addNewSpread,
  deleteImage,
  deleteSpread,
  replaceImage,
  swapImage,
  updateCover,
  updateCoverFonts,
  updateCoverPosition,
  updateCoverTitles,
  updateImageBgPosition,
  updatePhotobookLayout
} from 'modules/ScrPhotobook/store/slices/photobook/actions';
import { RootState } from 'old-store/store';

const capturePreviousStateMiddleware = createListenerMiddleware();
let previousState: any = null;

capturePreviousStateMiddleware.startListening({
  matcher: isAnyOf(
    addNewSpread.pending,
    deleteSpread.pending,
    addImageToThePage.pending,
    replaceImage.pending,
    updateImageBgPosition.pending,
    updatePhotobookLayout.pending,
    deleteImage.pending,
    swapImage.pending,
    updateCover.pending,
    updateCoverPosition.pending,
    updateCoverTitles.pending,
    updateCoverFonts.pending
  ),
  effect: (action, { getState }) => {
    // Previous state before the action is fulfilled
    const state = getState() as RootState;
    previousState = state.photobook;
  }
});

const requestSavingMiddleware = createListenerMiddleware();

requestSavingMiddleware.startListening({
  matcher: isAnyOf(
    addNewSpread.fulfilled,
    deleteSpread.fulfilled,
    addImageToThePage.fulfilled,
    replaceImage.fulfilled,
    updateImageBgPosition.fulfilled,
    updatePhotobookLayout.fulfilled,
    deleteImage.fulfilled,
    swapImage.fulfilled,
    updateCover.fulfilled,
    updateCoverPosition.fulfilled,
    updateCoverTitles.fulfilled,
    updateCoverFonts.fulfilled
  ),
  effect: (action, { dispatch }) => {
    // Fulfilled action with its payload and previous state
    dispatch(
      saveRequest({
        action: action.type,
        payload: previousState
      })
    );
  }
});

export const combinedPhotobookMiddlewares = [
  capturePreviousStateMiddleware.middleware,
  requestSavingMiddleware.middleware
];
