export function getImageCoordsForFocalPoint({ image, view }: IGetImageCoordsForFocalPointParams) {
  const viewCenter = {
    x: view.width / 2,
    y: view.height / 2
  };

  const focalPointX = image.width * (image.focalPoint.xPercent / 100);
  const focalPointY = image.height * (image.focalPoint.yPercent / 100);
  const x = Math.max(Math.min(viewCenter.x - focalPointX, 0), view.width - image.width);
  const y = Math.max(Math.min(viewCenter.y - focalPointY, 0), view.height - image.height);

  return {
    x,
    y
  };
}

export interface IGetImageCoordsForFocalPointParams {
  image: {
    width: number;
    height: number;
    focalPoint: {
      xPercent: number;
      yPercent: number;
    };
  };
  view: {
    width: number;
    height: number;
  };
}

export interface IImageCoords {
  x: number;
  y: number;
}
