import { Alert, Col } from 'reactstrap';
import { useSelector } from 'react-redux';
import { getCheckoutStore } from 'modules/ScrShop/store/selectors/checkout';
import { getTranslationKey } from 'helpers/texting';
import styled from 'styled-components';
import moment from 'moment';

const Text = styled.div`
  margin: 10px;
`;

export function OrderDeadlineBanner({
  colMd = 12,
  colXs = 12
}: {
  colMd: number | undefined;
  colXs: number | undefined;
}) {
  const { _laboratory: laboratory } = useSelector(getCheckoutStore);
  const showBanner = moment().isBetween(moment('2024-11-01'), moment('2024-12-24'));

  if (!showBanner) return null;

  const renderBanner = function () {
    if (!laboratory) {
      return (
        <Alert color="danger">
          <Text dangerouslySetInnerHTML={{ __html: getTranslationKey('banners.pleaseNote') }} />
        </Alert>
      );
    }

    // nPhoto
    if (laboratory === '5f92ee2cac100ce9bf64384c') {
      return (
        <Alert color="danger">
          <Text
            dangerouslySetInnerHTML={{ __html: getTranslationKey('banners.orderDeadlineNPhoto') }}
          />
        </Alert>
      );
    }

    // PixelfotoExpress
    if (laboratory === '60914f66db344022409079f9') {
      return (
        <Alert color="danger">
          <Text
            dangerouslySetInnerHTML={{
              __html: getTranslationKey('banners.orderDeadlinePixelfotoExpress')
            }}
          />
        </Alert>
      );
    }

    // Picanova
    if (laboratory === '64dcc2ccd599833c1c3784a2') {
      return (
        <Alert color="danger">
          <Text
            dangerouslySetInnerHTML={{ __html: getTranslationKey('banners.orderDeadlinePicanova') }}
          />
        </Alert>
      );
    }
  };

  return (
    <Col md={colMd} xs={colXs}>
      {renderBanner()}
    </Col>
  );
}
