import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import {
  addImageToThePage,
  addNewSpread,
  createPhotobook,
  deleteImage,
  deletePhotobook,
  deleteSpread,
  fetchLayouts,
  fetchPhotobooks,
  fetchPreviewPhotobook,
  replaceImage,
  reuploadPreviousState,
  showSuccessNotify,
  swapImage,
  updateImageBgPosition,
  updatePhotobookLayout,
  updatePhotobookTitle
} from './actions';
import { addCoverSpread } from 'modules/ScrPhotobook/helpers/addCoverSpread';
import { isString, uniqBy } from 'lodash';

export interface IBoundsRelative {
  heightPercent: number;
  widthPercent: number;
  leftPercent: number;
  topPercent: number;
}

export interface ISpreadImage {
  id: string;
  frameIndex: number;
  boundsRelative: IBoundsRelative;
}

export interface IPhotobookSpread {
  _id: string;
  index: number;
  frameLayout: null | string;
  images: ISpreadImage[];
  isCoverSlide?: boolean;
  cover?: string;
}

export interface IPhotobookPricing {
  price?: number;
  priceForAdditionalPages?: number;
  priceTotal?: number;
}

export interface IProductDetails {
  color?: string;
  coverTextPosition?: CoverPositions;
  coverFirstTitle?: string;
  coverSecondTitle?: string;
  coverFirstTitleFont?: string;
  coverSecondTitleFont?: string;
}

type PhotobookMode = 'crop' | 'swap' | '';
export type CoverPositions = 'C' | 'D' | 'E' | 'F' | 'G';
export const coverPositionsArray: CoverPositions[] = ['C', 'D', 'E', 'F', 'G'];

export interface IPhotobook {
  _id: string;
  _user: string;
  _endcustomer: string;
  _product: string;
  _collection: string;

  title: string;
  slug: string;
  aspectRatio: number;

  productImage: string;
  details: {
    usedImages: string[];
    numberOfUsedImages: number;
    numberOfPages: number;
    numberOfSpreads: number;
  };
  productInformation: {
    size: number;
    color: string;
    minAmountOfPages: number;
    maxAmountOfPages: number;
  };
  productDetails: IProductDetails;

  spreads: IPhotobookSpread[];

  availableSelections: string[];
  authorizedEditors: string[];
  sharedViewers: string[];

  pricing: IPhotobookPricing;

  isDeleted: boolean;
  finalDeletionAt: Date;

  createdAt: string;
  updatedAt: string;
}

export interface ILayout {
  _id: string;
  numberOfFrames: number;
  frames: Array<{ boundsRelative: IBoundsRelative }>;
}

export interface IPhotobookState {
  photobooks: IPhotobook[];
  purchasedPhotobooks: IPhotobook[];
  layouts: ILayout[];
  activePageIdx: number | null;
  activatedImageId: string;
  isImageDragged?: boolean;
  isMobileCoverEdit?: boolean;
  mode: PhotobookMode;
  lastUpdate?: string;
  isUpdateProcessed?: boolean;
  activeCoverField?: string;
  startingFirstTitle?: string;
  startingSecondTitle?: string;
  coverDulpicateFirstTitle?: string;
  coverDulpicateSecondTitle?: string;
  requestHistory: IRequestHistory;
}

interface IRequestHistory {
  past: IPhotobookState[];
  present: IPhotobookState | null;
  future: IPhotobookState[];
}

const initialState: IPhotobookState = {
  photobooks: [],
  purchasedPhotobooks: [],
  layouts: [],
  activePageIdx: 0,
  activatedImageId: '',
  isImageDragged: false,
  isMobileCoverEdit: false,
  mode: '',
  lastUpdate: '',
  isUpdateProcessed: false,
  activeCoverField: '',
  startingFirstTitle: '',
  startingSecondTitle: '',
  coverDulpicateFirstTitle: '',
  coverDulpicateSecondTitle: '',
  requestHistory: {
    past: [],
    present: null,
    future: []
  }
};

const photobookSlice = createSlice({
  name: 'photobook',
  initialState,
  reducers: {
    setPhotobook(state, { payload }: PayloadAction<IPhotobookState | null>) {
      Object.assign(state, payload);
    },
    setActivePageIdx(state, { payload }: PayloadAction<number | null>) {
      const isPageChanged = state.activePageIdx !== payload;
      if (!isPageChanged) return;

      state.activePageIdx = payload;
      state.activatedImageId = '';
      state.mode = '';
    },
    setFirstPageAsCover(state, { payload }: PayloadAction<{ id: string }>) {
      const currentPhotobookIdx = state.photobooks.findIndex((item) => item._id === payload.id);

      state.photobooks[currentPhotobookIdx].spreads[0].isCoverSlide = true;
    },
    changePhotobookTitle(state, { payload }: PayloadAction<{ id: string; value: string }>) {
      const currentPhotobookIdx = state.photobooks.findIndex((item) => item._id === payload.id);

      state.photobooks[currentPhotobookIdx].title = payload.value;
    },
    updateSpreads(
      state,
      { payload }: PayloadAction<{ photobookId: string; spreads: IPhotobookSpread[] }>
    ) {
      const currentPhotobookIdx = state.photobooks.findIndex(
        (item) => item._id === payload.photobookId
      );

      const spreads = [...payload.spreads];

      const coverSpread = { _id: '', frameLayout: '', images: [], index: 0, isCoverSlide: true };

      spreads.unshift(coverSpread);

      const spreadsWithIndex = spreads.map((spread, index) => ({ ...spread, index }));

      state.photobooks[currentPhotobookIdx].spreads = spreadsWithIndex;
    },
    updatePricing(
      state,
      { payload }: PayloadAction<{ photobookId: string; pricing: IPhotobookPricing }>
    ) {
      const currentPhotobookIdx = state.photobooks.findIndex(
        (item) => item._id === payload.photobookId
      );

      state.photobooks[currentPhotobookIdx].pricing = payload.pricing;
    },
    setActiveImageId(state, { payload }: PayloadAction<{ activatedImageId: string }>) {
      state.activatedImageId = payload.activatedImageId;
      state.mode = '';
    },
    startImageDrag(state) {
      state.isImageDragged = true;
    },
    endImageDrag(state) {
      state.isImageDragged = false;
    },
    setPhotobookMode(state, { payload }: PayloadAction<PhotobookMode>) {
      const isSameModeSelected = state.mode === payload;
      state.mode = isSameModeSelected ? '' : payload;
    },
    setPhotobookCover(state, { payload }: PayloadAction<{ photobookId: string; cover?: string }>) {
      const currentPhotobookIdx = state.photobooks.findIndex(
        (item) => item._id === payload.photobookId
      );

      state.photobooks[currentPhotobookIdx].productDetails.color = payload.cover;
    },
    setPhotobookCoverText(
      state,
      {
        payload
      }: PayloadAction<{
        photobookId: string;
        coverTextPosition?: CoverPositions;
        coverFirstTitle?: string;
        coverSecondTitle?: string;
        coverFirstTitleFont?: string;
        coverSecondTitleFont?: string;
      }>
    ) {
      const currentPhotobookIdx = state.photobooks.findIndex(
        (item) => item._id === payload.photobookId
      );

      const currentDetails = state.photobooks[currentPhotobookIdx].productDetails;

      if (payload.coverTextPosition) {
        currentDetails.coverTextPosition = payload.coverTextPosition;
      }

      if (isString(payload.coverFirstTitle)) {
        currentDetails.coverFirstTitle = payload?.coverFirstTitle || '';
      }
      if (isString(payload.coverSecondTitle)) {
        currentDetails.coverSecondTitle = payload?.coverSecondTitle || '';
      }

      if (payload.coverFirstTitleFont) {
        currentDetails.coverFirstTitleFont = payload.coverFirstTitleFont;
      }
      if (payload.coverSecondTitleFont) {
        currentDetails.coverSecondTitleFont = payload.coverSecondTitleFont;
      }
    },
    updatePhotobookDuplicateCoverText(
      state,
      { payload }: PayloadAction<{ coverFirstTitle?: string; coverSecondTitle?: string }>
    ) {
      if (isString(payload.coverFirstTitle)) {
        state.coverDulpicateFirstTitle = payload?.coverFirstTitle || '';
      }
      if (isString(payload.coverSecondTitle)) {
        state.coverDulpicateSecondTitle = payload?.coverSecondTitle || '';
      }
    },
    setStartingTitle(
      state,
      { payload }: PayloadAction<{ coverFirstTitle: string; coverSecondTitle: string }>
    ) {
      state.startingFirstTitle = payload.coverFirstTitle;
      state.startingSecondTitle = payload.coverSecondTitle;
    },
    setActiveField(state, { payload }: PayloadAction<string>) {
      state.activeCoverField = payload;
    },
    startMobileCoverEditing(state) {
      state.isMobileCoverEdit = true;
    },
    endMobileCoverEditing(state) {
      state.isMobileCoverEdit = false;
    },
    setLastUpdate(state, { payload }: PayloadAction<string>) {
      state.lastUpdate = payload;
    },
    saveRequest(state, { payload }: PayloadAction<{ action: string; payload: IPhotobookState }>) {
      // already went some steps back and made changes - means all the previous history is not valid anymore
      if (state.requestHistory.future.length) {
        state.requestHistory.past = [];
      }
      if (state.requestHistory.past.length >= 10) {
        state.requestHistory.past = state.requestHistory.past.slice(-10);
      }

      state.requestHistory.past.push(payload.payload);
      state.requestHistory.future = [];
    },
    undoRequest(state) {
      if (state.requestHistory.past.length > 0) {
        state.requestHistory.future.unshift(state);
        state.requestHistory.past.pop()!;
        state.lastUpdate = new Date().toISOString();
      }
    },
    redoRequest(state) {
      if (state.requestHistory.future.length > 0) {
        state.requestHistory.past.push(state);
        state.requestHistory.future.shift()!;
        state.lastUpdate = new Date().toISOString();
      }
    },
    clearRequestHistory(state) {
      state.requestHistory = {
        past: [],
        present: null,
        future: []
      };
    }
  },
  extraReducers: (builder) => {
    // @ts-ignore
    builder.addCase(
      fetchPhotobooks.fulfilled,
      (
        state,
        {
          payload
        }: PayloadAction<{ availablePhotobooks: IPhotobook[]; purchasedPhotobooks: IPhotobook[] }>
      ) => {
        const { availablePhotobooks, purchasedPhotobooks } = payload;
        const { photobooksWithCoverSpread } = addCoverSpread(availablePhotobooks);

        const previousPhotobooks = current(state.photobooks);

        // Map of existing photobooks by _id for efficient lookups
        const photobookMap = new Map<string, IPhotobook>(
          previousPhotobooks.map((photobook) => [photobook._id, photobook])
        );

        photobooksWithCoverSpread.forEach((newPhotobook) => {
          const existingPhotobook = photobookMap.get(newPhotobook._id);

          if (!existingPhotobook || newPhotobook.pricing) {
            photobookMap.set(newPhotobook._id, newPhotobook);
          }
        });

        // Update state.photobooks
        state.photobooks = Array.from(photobookMap.values());

        // Update purchasedPhotobooks with unique values
        state.purchasedPhotobooks = uniqBy(purchasedPhotobooks, '_id');
      }
    );
    builder.addCase(fetchPreviewPhotobook.fulfilled, (state, { payload }) => {
      const previousPhotobooks = current(state.photobooks);

      const { photobooksWithCoverSpread } = addCoverSpread([payload]);

      // Map of existing photobooks by _id for efficient lookups
      const photobookMap = new Map<string, IPhotobook>(
        previousPhotobooks.map((photobook) => [photobook._id, photobook])
      );

      photobooksWithCoverSpread.forEach((newPhotobook) => {
        const existingPhotobook = photobookMap.get(newPhotobook._id);

        if (!existingPhotobook || newPhotobook.pricing) {
          photobookMap.set(newPhotobook._id, newPhotobook);
        }
      });

      // Update state.photobooks
      state.photobooks = Array.from(photobookMap.values());
    });
    builder.addCase(fetchLayouts.fulfilled, (state, { payload }) => {
      state.layouts = payload;
    });
    builder.addCase(createPhotobook.fulfilled, () => {
      showSuccessNotify();
    });
    builder.addCase(deletePhotobook.fulfilled, (state, { payload }) => {
      state.photobooks = state.photobooks.filter((photobook) => photobook._id !== payload);
    });

    // Processing actions
    builder.addCase(updatePhotobookTitle.pending, (state) => {
      state.isUpdateProcessed = true;
    });
    builder.addCase(updatePhotobookTitle.fulfilled, (state) => {
      state.isUpdateProcessed = false;
    });

    builder.addCase(updatePhotobookLayout.pending, (state) => {
      state.isUpdateProcessed = true;
    });
    builder.addCase(updatePhotobookLayout.fulfilled, (state) => {
      state.isUpdateProcessed = false;
    });

    builder.addCase(addNewSpread.pending, (state) => {
      state.isUpdateProcessed = true;
    });
    builder.addCase(addNewSpread.fulfilled, (state) => {
      state.isUpdateProcessed = false;
    });

    builder.addCase(deleteSpread.pending, (state) => {
      state.isUpdateProcessed = true;
    });
    builder.addCase(deleteSpread.fulfilled, (state) => {
      state.isUpdateProcessed = false;
    });

    builder.addCase(addImageToThePage.pending, (state) => {
      state.isUpdateProcessed = true;
    });
    builder.addCase(addImageToThePage.fulfilled, (state) => {
      state.isUpdateProcessed = false;
    });

    builder.addCase(replaceImage.pending, (state) => {
      state.isUpdateProcessed = true;
    });
    builder.addCase(replaceImage.fulfilled, (state) => {
      state.isUpdateProcessed = false;
    });

    builder.addCase(updateImageBgPosition.pending, (state) => {
      state.isUpdateProcessed = true;
    });
    builder.addCase(updateImageBgPosition.fulfilled, (state) => {
      state.isUpdateProcessed = false;
    });

    builder.addCase(deleteImage.pending, (state) => {
      state.isUpdateProcessed = true;
    });
    builder.addCase(deleteImage.fulfilled, (state) => {
      state.isUpdateProcessed = false;
    });

    builder.addCase(swapImage.pending, (state) => {
      state.isUpdateProcessed = true;
    });
    builder.addCase(swapImage.fulfilled, (state) => {
      state.isUpdateProcessed = false;
    });

    builder.addCase(reuploadPreviousState.pending, (state) => {
      state.isUpdateProcessed = true;
    });
    builder.addCase(reuploadPreviousState.fulfilled, (state) => {
      state.isUpdateProcessed = false;
    });
  }
});

export const {
  setPhotobook,
  setActivePageIdx,
  changePhotobookTitle,
  updateSpreads,
  updatePricing,
  setActiveImageId,
  startImageDrag,
  endImageDrag,
  setPhotobookMode,
  setPhotobookCover,
  setPhotobookCoverText,
  setActiveField,
  startMobileCoverEditing,
  endMobileCoverEditing,
  setLastUpdate,
  updatePhotobookDuplicateCoverText,
  setStartingTitle,
  saveRequest,
  undoRequest,
  redoRequest,
  clearRequestHistory
} = photobookSlice.actions;

export const photobookReducer = photobookSlice.reducer;
